import { InfiniteLoader,
  Cells,
  CellsTitle,
  Cell,
  CellBody,
  CellFooter,SearchBar} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {useState,useEffect} from 'react';
import {ApiGet} from '../../util/api';
import { useNavigate} from 'react-router-dom';
import { Affix, Button } from 'antd';
import PubSub from 'pubsub-js';

function ResumeList({setResumeName,setResumeId,setCandidateName}) {
    const navigate = useNavigate()
    const [resumes, setResumes] = useState([]);
    const [top, setTop] = useState(300);
    const [searchKey, setSearchKey] = useState('');
    const getList = () => {
        ApiGet('/candidates/mycandidates?key='+searchKey).then(res => {
            if(res.data.code == 200){
                setResumes(res.data.candidates)
            }else{
                setResumes([])
            }
        })
    }
    PubSub.subscribe('refreshRuseme',(msg,data) =>{
        getList();
    })
    useEffect(() => {
        getList();
    },[searchKey])
    return (
    <div>
        {setResumeName && setResumeId?null:<Affix style={{marginLeft:'70%',marginTop:'-33px'}} offsetTop={top}>
            <Button type="primary" onClick={() => {
                navigate('/uploadResume')}}>
            上传
            </Button>
        </Affix>}
        <SearchBar
            onChange={(value) => {
                setSearchKey(value);
            }}
            // defaultValue={this.state.searchText}
            placeholder="搜索"
            lang={{
                cancel: '搜索'
            }}
        />
        <InfiniteLoader
            onLoadMore={ (resolve, finish) => {
                //mock request
                setTimeout( ()=> {
                    if(resumes.length > 22){
                        finish()
                    }else{
                    setResumes(resumes.concat([resumes.length]));
                    }
                }, 1000)
            }}
        >
        <Cells>
        {
            resumes.map( (item, i) => {
                return (
                    setResumeName ?
                    <Cell onClick={()=>{
                        setResumeName(item.resume);
                        setResumeId(item._id)
                        setCandidateName(item.name)
                    }} key={i} access>
                        <CellBody>
                            {item.name}
                        </CellBody>
                        <CellFooter/>
                    </Cell> : <Cell onClick={()=>{
                        navigate('/preview',{ state:{type:'resume',id:item._id,name:item.resume}});
                    }} key={i} access>
                        <CellBody>
                            {item.name}
                        </CellBody>
                        <CellFooter/>
                    </Cell>
                )
            })
        }
        </Cells>
        </InfiniteLoader>
    </div>
    );
}

export default ResumeList;
