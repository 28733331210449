import { Tab, TabBarItem, Article} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import IconButton from '../home/images/icon_nav_button.png';
import IconMsg from '../home/images/icon_nav_msg.png';
import IconArticle from '../home/images/icon_nav_article.png';
import Helper from '../helper/index';
import ResumeList from '../resumeList';
import FormatList from '../formatList';
import {useState,useEffect} from 'react';
import Login from '../../component/login';
import PubSub from 'pubsub-js'

function Home() {
    const [showLogin, setShowLogin] = useState(false)
    const [index, setIndex] = useState(Number(localStorage.getItem('homeindex')))
    PubSub.subscribe('token',(msg,data) =>{
      console.log(data)
      setShowLogin(data != '')
    })
    const setDefaultIndex = (index) =>{
        setIndex(index)
        localStorage.setItem('homeindex',index);
        console.log(index)
    }
    useEffect(() => {
        console.log(index)
    },[])
    return (
        localStorage.getItem('token')==''||localStorage.getItem('token')==null?
        <Login/>:<Tab type="tabbar" defaultIndex={index} onChange={index=>setDefaultIndex(index)}>
            <TabBarItem icon={<img src={IconMsg}/> }  label="候选人">
                <ResumeList/>
            </TabBarItem>
            <TabBarItem icon={<img src={IconArticle}/> } label="历史记录">
                <FormatList/>
            </TabBarItem>
            <TabBarItem icon={<img src={IconButton}/>} label="猎头助手">
                <Helper/>
            </TabBarItem>
        </Tab>
    );
}

export default Home;
