import 'weui';
import 'react-weui/build/packages/react-weui.css';
import { Button,ButtonArea,Toptips,Form,
  FormCell,CellHeader,CellBody,Input,Label,Page,LoadMore} from 'react-weui';
import {Upload} from 'antd'
import {useState,useEffect} from 'react';
import OSS from 'ali-oss';
import CryptoJS from 'crypto-js'
import {ApiGet} from '../../util/api';
import { useNavigate } from 'react-router-dom';
import PubSub from 'pubsub-js';

function Resume({setResumeName,setResumeId,setCandidateName}) {
  const [fileList,setFileList] = useState([])
  const [progress,setProgress] = useState(0)
  const [showLoading, setShowLoading] = useState(false)
  const [loadMsg, setLoadMsg] = useState("正在上传...")
  const [showToptips, setShowToptips] = useState(false)
  const [error, setError] = useState('请填写候选人名字')
  const [name, setName] = useState('')
  const navagate = useNavigate()

  const onChange = (file) => {
    if(name == ''){
        setShowToptips(true)
        window.setTimeout(e=> setShowToptips(false), 2000)
        return;
    }
    setShowLoading(true)
    console.log(file.file)
    const md5 = CryptoJS.MD5(file.file).toString();;
    console.log(md5)
    const url = '/candidates/upload?type=0&md5='+md5+'&filename='+file.file.name+'&resumename='+name;
    ApiGet(url).then(async res => {
        if(res.data.code == 200){
            const client = new OSS({
              region: res.data.region,
              accessKeyId: res.data.accessKeyId,
              accessKeySecret: res.data.accessKeySecret,
              bucket: res.data.bucket,
              stsToken: res.data.stsToken,
              // 刷新临时访问凭证。
              refreshSTSToken: async () => {
                const refreshToken = await ApiGet(url);
                return {
                  accessKeyId: refreshToken.accessKeyId,
                  accessKeySecret: refreshToken.accessKeySecret,
                  stsToken: refreshToken.stsToken,
                };
              },
            });
            try {
              // 使用OSS客户端上传文件
              const result = await client.put(res.data.filename, file.file);
              console.log(result);
              if(setResumeName && setResumeId){
                setResumeName(res.data.filename);
                setResumeId(res.data.id);
                setCandidateName(name);
              }else{
                PubSub.publish('refreshRuseme',true);
                navagate(-1)
              }
            } catch (e) {
              console.error(e);
            }
        }else{
          console.error(res.data.msg);
          setLoadMsg(res.data.msg);
          navagate(-1)
        }
        setShowLoading(false)
    }).catch(e=>{
      console.error(e);
      setLoadMsg(e);
      navagate(-1)
    })
    
  }
  return (
    <Page  title="Input" subTitle="表单输入">
      <Toptips type="warn"
          show={showToptips}
      >{error}</Toptips>
      <LoadMore loading style={{display:showLoading?'block':'none',marginTop:'300px'}}>{loadMsg}</LoadMore>
      <div style={{display:!showLoading?'block':'none',marginTop:'300px'}}> 
        <Form>
          <FormCell>
              <CellHeader>
                  <Label>名字</Label>
              </CellHeader>
              <CellBody>
                  <Input  type="text" placeholder="请输入候选人名字" value={name} onChange={event =>{
                    setName(event.target.value)
                  }}/>
              </CellBody>
          </FormCell>
        </Form>    
        <ButtonArea>
            <Upload 
                accept='.jpeg ,.jpg ,.png,.pdf,.docx,.doc'
                fileList={fileList}
                maxCount='1'
                beforeUpload={
                  ()=>{
                    return false
                  }
                }
                onChange={(event)=>{
                    onChange(event)
                }}>
                <Button >上传简历</Button>
            </Upload>
        </ButtonArea>
      </div>
    </Page>
  );
}

export default Resume;