import axios from 'axios';
const Host = 'https://api.soseein.com:11443';
export const ApiGet = (path) => {
    const service =axios.create({
        timeout: 1000000,
        headers:{
        'token': localStorage.getItem('token')
        }
    })
    service.interceptors.response.use(
        response => {
            if(response.data.code == 400){
                console.log("登录失效，请重新登录")
                localStorage.setItem('token','');
                return Promise.reject("登录失效，请重新登录")
            }
            return response;
        },
        error => {
            console.log('err' + error) // for debug
            return Promise.reject(error)
        }
    )
    return service.get(Host + path);
}
export const ApiDownload = (path,fileName,fileType) => {
    axios.get({
        url:path,
        responseType: 'blob',
    }).then(res=>{
        //创建一个链接DOM节点
        console.log(path)
        const link = document.createElement('a');
        // 创建一个新的Blob对象
        const blob = new Blob([res.data],{ type: fileType});
        // 创建blob链接
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;//下载的文件名加后缀 可自定义
        // 隐藏DOM节点
        link.style.display = 'none';
        // 将链接节点添加到DOM中
        document.body.appendChild(link);
        // 模拟点击下载
        link.click();
        // 移除DOM节点
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    })
}
export const ApiPost = (path,data) => {
    return axios.create({
        headers:{
        'token': localStorage.getItem('token')
        }
    }).post(Host + path,data)
}