import { InfiniteLoader,
    Cells,
    CellsTitle,
    Cell,
    CellBody,
    CellFooter,SearchBar} from 'react-weui';
  import 'weui';
  import 'react-weui/build/packages/react-weui.css';
  import {useState,useEffect} from 'react';
  import {ApiGet} from '../../util/api';
  import { useNavigate} from 'react-router-dom';
  import PubSub from 'pubsub-js';
  
  function FormatList() {
      const navigate = useNavigate()
      const [formats, setFormats] = useState([]);
      const [searchKey, setSearchKey] = useState('');
      const getList = () => {
          ApiGet('/candidates/myformats?key='+searchKey).then(res => {
              if(res.data.code == 200){
                setFormats(res.data.formats)
              }else{
                setFormats([])
              }
          })
      }
      PubSub.subscribe('refreshFormat',(msg,data) =>{
          getList();
      })
      useEffect(() => {
          getList();
      },[searchKey])
      return (
      <div>
          <SearchBar
              onChange={(value) => {
                  setSearchKey(value);
              }}
              // defaultValue={this.state.searchText}
              placeholder="搜索"
              lang={{
                  cancel: '搜索'
              }}
          />
          <InfiniteLoader
              onLoadMore={ (resolve, finish) => {
                  //mock request
                  setTimeout( ()=> {
                      if(formats.length > 22){
                          finish()
                      }else{
                        setFormats(formats.concat([formats.length]));
                      }
                  }, 1000)
              }}
          >
          <Cells>
          {
              formats.map( (item, i) => {
                  return (
                      <Cell onClick={()=>{
                          navigate('/preview',{ state:{type:'format',id:item._id,name:item.filename}});
                      }} key={i} access>
                          <CellBody>
                              {item.name}
                          </CellBody>
                          <CellFooter/>
                      </Cell>
                  )
              })
          }
          </Cells>
          </InfiniteLoader>
      </div>
      );
  }
  
  export default FormatList;
  