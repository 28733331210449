import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UploadResume from './page/uploadResume';
import UploadTemplet from './page/uploadTemplet';
import ResumeFormat from './page/resumeFormat'
import Home from './page/home';
import Preview from './page/preview';
import FormatList from './page/formatList';
import TempletList from './page/templetList';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <Routes>
          <Route path={"/"} element={<Home/>} />
          <Route path={"/uploadResume"} element={<UploadResume/>} />
          <Route path={"/uploadTemplet"} element={<UploadTemplet/>} />
          <Route path={"/resumeFormat"} element={<ResumeFormat/>} />
          <Route path={"/preview"} element={<Preview/>} />
          <Route path={"/formatList"} element={<FormatList/>} />
          <Route path={"/templetList"} element={<TempletList/>} />
          {/* 你可以添加更多的路由 */}
        </Routes>
      </div>
    </BrowserRouter>
    
  );
}

export default App;
