import { Button} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import './index.css'
import { useNavigate} from 'react-router-dom';
function Helper() {
    const navigate = useNavigate()
  return (
        <div className="Container" >
            <Button onClick={()=>{navigate('/resumeFormat')}} >简历格式化</Button>
            <Button className="Button" onClick={()=>{navigate('/templetList')}} >管理模板</Button>
            <Button className="Button"
            onClick={()=>{
              localStorage.setItem('token','');
              window.location.reload();}} >退出登录</Button>
        </div>
  );
}
export default Helper;