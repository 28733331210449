import { InfiniteLoader,
    Cells,
    CellsTitle,
    Cell,
    CellBody,
    CellFooter,SearchBar} from 'react-weui';
  import 'weui';
  import 'react-weui/build/packages/react-weui.css';
  import {useState,useEffect} from 'react';
  import {ApiGet} from '../../util/api';
  import { Affix, Button } from 'antd';
  import { useNavigate} from 'react-router-dom';
  import PubSub from 'pubsub-js';
  
  function TempletList({setTempletName,setTempletId}) {
    const navigate = useNavigate()
    const [templets, setTemplets] = useState([]);
    const [top, setTop] = useState(300);
    const [searchKey, setSearchKey] = useState('');
    const getList = () => {
        ApiGet('/candidates/mytemplets?key='+searchKey).then(res => {
            if(res.data.code == 200){
                setTemplets(res.data.templets)
            }else{
                setTemplets([])
            }
        })
    }
    PubSub.subscribe('refreshTemplet',(msg,data) =>{
        getList();
    })
    useEffect(() => {
        getList();
    },[searchKey])
    return (
        <div>
            {setTempletName && setTempletId?null: <Affix style={{marginLeft:'70%',marginTop:'-33px'}} offsetTop={top}>
                <Button type="primary" onClick={() => {
                    navigate('/uploadTemplet')}}>
                上传
                </Button>
            </Affix>}
            <SearchBar
                onChange={(value) => {
                    setSearchKey(value);
                }}
                placeholder="搜索"
                lang={{
                    cancel: '搜索'
                }}
            />
            <InfiniteLoader
                onLoadMore={ (resolve, finish) => {
                    //mock request
                    setTimeout( ()=> {
                        if(templets.length > 22){
                            finish()
                        }else{
                        setTemplets(templets.concat([templets.length]));
                        }
                    }, 1000)
                }}
            >
            <Cells>
            {
                templets.map( (item, i) => {
                    return (
                        setTempletName ?
                        <Cell onClick={()=>{
                            setTempletName(item.filename)
                            setTempletId(item._id)
                        }}
                         key={i} access>
                            <CellBody>
                                {item.name}
                            </CellBody>
                            <CellFooter/>
                        </Cell> : <Cell onClick={()=>{
                            navigate('/preview',{ state:{type:'templet',id:item._id,name:item.filename}});
                        }} key={i} access>
                            <CellBody>
                                {item.name}
                            </CellBody>
                            <CellFooter/>
                        </Cell>
                    )
                })
            }
            </Cells>
            </InfiniteLoader>
        </div>
          
    );
  }
  
  export default TempletList;
  