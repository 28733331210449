import { Button,ButtonArea,Toptips,Form,
  FormCell,CellHeader,CellBody,Input,Label,Page} from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import { useNavigate} from 'react-router-dom';
import {useState} from 'react';
import './index.css'
import {ApiGet} from '../../util/api';
import PubSub from 'pubsub-js'

function Login() {
  const navigate = useNavigate()
  const [showToptips, setShowToptips] = useState(false)
  const [name, setName] = useState('')
  const [pwd, setPwd] = useState('')
  const [error, setError] = useState('请输入账号密码')

  return (
    <Page className="LoginContainer" title="Input" subTitle="表单输入">
      <Toptips type="warn"
          show={showToptips}
      >{error}</Toptips>
      <Form>
          <FormCell>
              <CellHeader>
                  <Label>用户名</Label>
              </CellHeader>
              <CellBody>
                  <Input  type="text" placeholder="请输入用户名" value={name} onChange={event =>{
                    setName(event.target.value)
                  }}/>
              </CellBody>
          </FormCell>
          <FormCell>
              <CellHeader>
                  <Label>密码</Label>
              </CellHeader>
              <CellBody>
                  <Input type="password" placeholder="请输入密码" value={pwd}  onChange={event =>{
                    setPwd(event.target.value)
                  }}/>
              </CellBody>
          </FormCell>
        </Form>    
        <ButtonArea>
            <Button 
                //button to display toptips
                onClick={ e=> {
                    if(name == '' || pwd == ''){
                        setError('请输入账号密码');
                        setShowToptips(true)
                        window.setTimeout(e=> setShowToptips(false), 2000)
                        return;
                    }
                    ApiGet('/users/login?name='+name+'&pwd='+pwd).then(res => {
                        if(res.data.code == 200){
                            localStorage.setItem('token',res.data.token);
                            window.location.reload();
                        }else{
                            setError(res.data.message);
                            setShowToptips(true);
                            
                        }
                    })
                }
            }>
                登录
            </Button>
        </ButtonArea>
    </Page>
  );
}

export default Login;
